import React from 'react'
import Helmet from 'react-helmet';
import Layout from '../components/layout'

const ThanksPage = () => (
    <Layout>
        <Helmet title={"Konfirmasi Email Berhasil"} />
        <h1></h1>

        <section id="download" className="main style1 special">
            <div className="container">
                <header className="major">
                    <h2>Terimaksih, Email berhasil dikonfirmasi.</h2>
                </header>
                <p> Nantikan informasi terbaru dari JagoReact.com.</p>
            </div>
        </section>

    </Layout>
)

export default ThanksPage
